@font-face {
  font-family: 'Open Sans';
  src: url('../public/assets/fonts/open_sansbold/OpenSans-Bold.woff2') format('woff2'),
      url('../public/assets/fonts/open_sansbold/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$base-font-size: 1.1rem;
$base-font-weight: normal;

body { margin: 0!important; padding: 0!important; box-sizing:border-box!important; overflow-x: hidden; font-family: "Open Sans", sans-serif; font-size: $base-font-size; font-weight: $base-font-weight; }
html {scroll-behavior: smooth;}

/****************** header section start ********************************/
.top_social_wrap {padding: 0px;margin: 0px;display: flex;flex-wrap: wrap;}
.top_social_wrap li {list-style: none;padding: 0px 10px;}
.top_social_wrap li a {color: #ffffff;}
.top_menu_content p {padding: 0px; margin: 0px;color: #ffffff; }
.top_menu_content a {text-decoration: none;color: #fada3e;}
.logo_wrap img {width: 80%; height: 100%; object-fit: contain; display: block; margin: 0px 0px 0px 80px;}
.header_wrap {background-color: #0071B3;text-align: center; padding: 8px 0px 13px 0px;}
.header_wrap p {padding: 0px; margin: 0px; color: #ffffff;}
.header_wrap a {color: #ffffff; }
/****************** header section end ********************************/

/****************** 202502 styles ********************************/
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  position: relative;
  overflow: hidden;
  * {
    color: white
  }

  .hero-overlay {
    background-color: rgba(4, 4, 1, 0.50);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .hero-text {
    padding: 0 15px;
    position: absolute;
    z-index: 2;
    text-shadow: 2px 2px 3px black;
    h1, h2 {
      font-weight: bold;
    }
  }
}

.what-is-heading {
  font-size: 1.75rem;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #0071B3;
  white-space: nowrap;
  &::after {content: ""; background-color: #0071B3; width: 100%; height: 4px; top: 0; display: block; border-radius: 2px;}
}

.sebt-intro {

  h3 {
    color:#0071B3;
    margin: 0;
    padding: 0 0 10px 0;
  }
}

.section-green-heading {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.summer-eligibility-green {
  background-color: #027C36;
  color: #FFF;
  border-radius: 10px;
  li:nth-child(n+1) {
    padding-top: 1rem;
  }
  a {
    color: white;
  }
}

.main-menu {
  text-align: center;
  .nav-links {
    margin: 0;
    li {
      display: inline-block;
      list-style-type: none;
      a {
        color: #000;
        display: inline-block;
        text-decoration: none;
        padding: 25px 15px;
        &:hover {
          color: #027C36;
        }
      }
      select {
        border: unset;
        cursor: pointer;
      }
    }
  }
}

.carrots {
  background-image: url("../public/assets/images/feature_points_converted.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.contact-page {
  h1 {
    color: #027e36;
  }
  .right {
    background-image: url("../public/assets/images/desktop-contact.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
  }
  .number {
    position: relative;
    left: -40px;
  }
  .emphasize-green {
    color: #027e36;
    font-weight: bold;
  }
  a {
    color: black;
    text-decoration: none;
  }
  .pane {
    border-radius: 10px;
    position: relative;
    z-index: 2;
  }
  .overlay {
    background-color: rgba(4, 4, 1, 0.32);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .contact-block {
    background-color: #027e36;
    border-radius: 20px;
    color: #fff;
    * {
      color: #fff;
    }
    padding: 5px 20px;
  }
}

/****************** 202502 styles end ********************************/

/******************** section eligibility blue start *********************/
.eligibility_blue {background-color: #0071B3; padding: 20px 0px;}
.eligibility_blue img {width: 100%; min-height: 100%;}
.eligibility_blue h2 {color: #ffffff; }
.eligibility_blue p {color: #ffffff; padding-right: 70px;}
.eligibility_blue ul {margin: 0px; width: 100%; display: flex; flex-wrap: wrap; align-items: center; padding-right:10px; padding-bottom: 50px;}
.eligibility_blue ul li {color: #ffffff;   padding: 15px 15px; position: relative; }
/******************** section eligibility blue end *********************/

.image-grid-banner {
  height: 250px;
  .item {
    background-repeat: no-repeat;
    height: 250px;
    &.i-a {
      background-position: center;
      background-image: url("../public/assets/images/shelley-pauls-I58f47LRQYM-unsplash-250.jpg");
    }
    &.i-b {
      background-position: center;
      background-image: url("../public/assets/images/children-hugging-smiling-small-250.jpg");
    }
    &.i-c {
      background-position: center;
      background-image: url("../public/assets/images/pexels-michael-burrows-7129129-400w.jpg");
    }
    &.i-d {
      background-position: center;
      background-image: url("../public/assets/images/Add-some-sweet-250.jpg");
    }
  }
}

/******* footer section start ********************/
.footer_wrap {background-color: #027e36;}
.footer_wrap img {width: 50px; height: 50px;}
.first_info_para p {color: #ffffff; }
.contact_info_wrap h3 {color: #ffffff; text-align: center!important; display: flex; flex-wrap: wrap; align-items: end;position: relative; justify-content: center; padding-bottom: 20px;}
.contact_info_wrap h3::before{content: ""; background-color: #008edf; width: 33%; height: 2px; position: absolute; left: 34%; top: 40px;}
.contact_info_wrap p{color: #ffffff; }
.contact_info_wrap a {text-decoration: none; color: #ffffff;}
.calling_wrap p {color: #ffffff; }
.calling_wrap a {text-decoration: none; color: #ffffff; }
.calling_wrap {display: flex; flex-direction: column; align-self: end; margin-top: 55px;}

 /******* footer section end ********************/


 h1>button.edit {
   float: right;
 }
 h1>div.edit {
  font-size: 18px;
 }


.index_benefits_wrap {background-color: #ffffff; padding-bottom: 50px;}
.index_benefits_wrap h2 {color:#027e36;   position: relative; display: flex; align-items: center; justify-content: space-between; margin: 0px; white-space:nowrap;}
.index_benefits_wrap h2::after {content: ""; background-color: #027e36; width: 87%; height: 4px; top: 0; display: block; border-radius: 2px;}
.index_benefits_wrap a.pdf { color: 008edf; text-decoration: underline; }
.index_benefits_wrap h3 {margin-top:20px;}
.index_benefits_wrap ul {list-style-type:none; }
.index_benefits_wrap ul li a {margin-left:10px; }


.pad-top-small {padding-top: 10px}
.pad-top-med {padding-top: 25px}
.margin-top-med {margin-top: 25px}
.contact-info {color: white}
.contact-info a {color: white; text-decoration: none;}
.admin-link {padding: 20px 0; text-align: center}
.white a {color: white; text-decoration: none;}