     /* XX-Large devices (larger desktops, 1400px and up) */
     .mobile_icon_wrap,
     .responsive_header_nav,
     .responsive_header {
       display: none;
     }

     @media (min-width: 1200px) and (max-width: 1375px) {
       .back_img_wrap {
         height: 550px;
       }

       .back_img_wrap img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: left;
       }
     }

     @media (max-width: 1186px) {
       .back_img_wrap {
         height: 550px;
       }

       .back_img_wrap img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: left;
       }
     }

     @media (max-width: 1097px) {
      .main-menu {
        .nav-links {
          li {
            a {
              padding: 15px 15px;
            }
            select {
              margin-bottom: 15px;
            }
          }
        }
      }
     }

     @media (max-width: 987px) {
       .footer_wrap {
         text-align: center;
       }

       .menu_section_wrap {
         display: none;
       }

       .mobile_icon_wrap {
         display: block;
         text-align: right;
       }

       .mobile_icon i {
         font-size: 3rem;
         padding-top: 12px;
         color: #027e36;
       }

       .responsive_header {
         display: block;
       }

       .responsive_header a {
         color: #fff;
         text-decoration: none;
         font-weight: 600;
       }

       .responsive_header li {
         list-style: none;
       }

       #open_nav {
         left: 0;
         z-index: 100000;
         width: 70%;
       }

       .responsive_header_nav {
         position: fixed;
         top: 0;
         left: 0;
         z-index: 20;
         height: 100%;
         display: block;
         box-shadow: 0px 0px 12px 2px #000;
         left: -380px;
         transition: left 0.4s ease;
       }

       .index_content_wrap h2 {
         font-size: 2.5rem;
       }

       .web_menu_bar {
         display: none;
       }

       .menu_holder {
         height: 119px;
       }

       .menu_holder {
         height: 100px;
         display: none;
       }

       .responsive_header_nav,
       .responsive_header {
         display: block;
         background-color: #027e36;
       }

       .calling_wrap {
        margin-top: 0;
      }
     }

     @media (max-width: 850px) {

       .index_banner_wrap {
         background-color: #027e36;
         color: #FFF;
         & .banner_content_wrap * {
           text-shadow: none;
         }
       }

       .banner_main_wrap {
         position: unset;
         padding: 20px 0px;
       }

       .back_img_wrap {
         height: auto;
       }

       .back_img_wrap {
         object-fit: unset;
         object-position: unset;
       }
     }

     @media (max-width: 800px) {
      .main-menu.top {
        display: none;
      }

      // TODO: Show hamburger below here
      .responsive_header {
        display: block;
      }
     }

     @media (max-width: 395px) {

       .eligibility_blue ul {
         padding-right: 0px;
        }

       .eligibility_blue  {
        .text-content {
          padding-left: 25px;
        }
       }

       .banner_content_wrap strong {
         line-height: normal;
       }

       .banner_content_wrap {
         padding: 0px 20px;
       }

       .banner_content_wrap h1 {
         font-size: 3.3rem;
       }

       .banner_content_wrap p {
         width: 100%;
       }
     }

     .white-background {
        background-color: #fff;
     }