/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui_awsui-motion-fade-in_155yk_1x7ys_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper_155yk_1x7ys_170:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper_155yk_1x7ys_170:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper_155yk_1x7ys_170:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_direction-button-wrapper-motion-enter_155yk_1x7ys_180:not(#\9),
.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9),
.awsui_direction-button-wrapper-motion-exit_155yk_1x7ys_182:not(#\9),
.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  pointer-events: none;
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-motion-enter_155yk_1x7ys_180:not(#\9),
  .awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9),
  .awsui_direction-button-wrapper-motion-exit_155yk_1x7ys_182:not(#\9),
  .awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-motion-enter_155yk_1x7ys_180:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-motion-enter_155yk_1x7ys_180:not(#\9), .awsui-motion-disabled .awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-motion-disabled .awsui_direction-button-wrapper-motion-exit_155yk_1x7ys_182:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-motion-exit_155yk_1x7ys_182:not(#\9), .awsui-motion-disabled .awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-up_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-up-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-block-start_155yk_1x7ys_206.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-down_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-down-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-block-end_155yk_1x7ys_233.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-left_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-right_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-left-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-right-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-right_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: awsui_slide-left_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-in_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-entering_155yk_1x7ys_181:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-right-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: awsui_slide-left-exit_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1)), awsui_awsui-motion-fade-out-0_155yk_1x7ys_1 var(--motion-duration-complex-vy4sma, 250ms) var(--motion-easing-responsive-vbn2t8, cubic-bezier(0, 0, 0, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9), .awsui-mode-entering .awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313.awsui_direction-button-wrapper-rtl_155yk_1x7ys_273.awsui_direction-button-wrapper-motion-exiting_155yk_1x7ys_183:not(#\9) {
  animation: none;
  transition: none;
}

@keyframes awsui_slide-up_155yk_1x7ys_1 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes awsui_slide-up-exit_155yk_1x7ys_1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 20px);
  }
}
@keyframes awsui_slide-down_155yk_1x7ys_1 {
  0% {
    transform: translate(0, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes awsui_slide-down-exit_155yk_1x7ys_1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -20px);
  }
}
@keyframes awsui_slide-left_155yk_1x7ys_1 {
  0% {
    transform: translate(20px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes awsui_slide-left-exit_155yk_1x7ys_1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(20px, 0);
  }
}
@keyframes awsui_slide-right_155yk_1x7ys_1 {
  0% {
    transform: translate(-20px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes awsui_slide-right-exit_155yk_1x7ys_1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-20px, 0);
  }
}
.awsui_drag-handle-wrapper_155yk_1x7ys_430:not(#\9) {
  position: relative;
  display: inline-block;
}

.awsui_portal-overlay_155yk_1x7ys_435:not(#\9) {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  pointer-events: none;
  z-index: 7000;
}

.awsui_portal-overlay-contents_155yk_1x7ys_443:not(#\9) {
  pointer-events: auto;
}

.awsui_drag-handle_155yk_1x7ys_430:not(#\9) {
  position: relative;
  display: flex;
}

.awsui_direction-button-wrapper_155yk_1x7ys_170:not(#\9) {
  position: absolute;
  block-size: var(--space-static-xl-qalw6z, 24px);
  inline-size: var(--space-static-xl-qalw6z, 24px);
  padding-block: var(--space-static-xxs-82cdfi, 4px);
  padding-inline: var(--space-static-xxs-82cdfi, 4px);
}

.awsui_direction-button-wrapper-hidden_155yk_1x7ys_460:not(#\9) {
  display: none;
}

.awsui_direction-button-wrapper-block-start_155yk_1x7ys_206:not(#\9) {
  inset-block-start: calc(-1 * (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)));
  inset-inline-start: calc(50% - (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)) / 2);
}

.awsui_direction-button-wrapper-block-end_155yk_1x7ys_233:not(#\9) {
  inset-block-end: calc(-1 * (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)));
  inset-inline-start: calc(50% - (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)) / 2);
}

.awsui_direction-button-wrapper-inline-start_155yk_1x7ys_260:not(#\9) {
  inset-inline-start: calc(-1 * (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)));
  inset-block-start: calc(50% - (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)) / 2);
}

.awsui_direction-button-wrapper-inline-end_155yk_1x7ys_313:not(#\9) {
  inset-inline-end: calc(-1 * (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)));
  inset-block-start: calc(50% - (var(--space-static-xl-qalw6z, 24px) + 2 * var(--space-static-xxs-82cdfi, 4px)) / 2);
}

.awsui_direction-button_155yk_1x7ys_170:not(#\9) {
  position: absolute;
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  touch-action: manipulation;
  inline-size: var(--space-static-xl-qalw6z, 24px);
  block-size: var(--space-static-xl-qalw6z, 24px);
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: var(--space-xxs-p8yyaw, 4px);
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;
  background-color: var(--color-background-direction-button-default-0p1y3c, #424650);
  color: var(--color-text-direction-button-default-554zrt, #ffffff);
  box-shadow: var(--shadow-dropdown-e5guvm, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
}
.awsui_direction-button_155yk_1x7ys_170:not(#\9):not(.awsui_direction-button-disabled_155yk_1x7ys_503):hover {
  background-color: var(--color-background-direction-button-hover-pwck9a, #333843);
}
.awsui_direction-button_155yk_1x7ys_170:not(#\9):not(.awsui_direction-button-disabled_155yk_1x7ys_503):active {
  background-color: var(--color-background-direction-button-active-hvd0pc, #232b37);
}

.awsui_direction-button-disabled_155yk_1x7ys_503:not(#\9) {
  cursor: default;
  background-color: var(--color-background-direction-button-disabled-54epp1, #ebebf0);
  color: var(--color-text-direction-button-disabled-ok08o0, #b4b4bb);
}