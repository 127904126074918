body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -118px;
}


.global-spinner {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
}

img.profile {
  border: 1px solid #ccc;
  width: 96px;
  height: 96px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
  height: auto;
}

span[class^="awsui_title"] {
  &:after {
    content: "by Asemio";
    font-size: 14px;
    font-weight: 400;
    margin-left: 6px;
  }
}

label[class*="awsui_label"],
div[class*="awsui_key-label"] {
  color: black !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  color: #027e36 !important;
}

button {
}

a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}

.green-background {
  background-color: #025022;
}

.white {
  color: #fff !important;
}

.normal-case {
  text-transform: none !important;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.required {
  &::after {
    content: " *";
    color: red;
    font-weight: 900;
  }
}

td {
  vertical-align: top;
}

.highlight {
  background-color: yellow;
}

span[class^="awsui_placeholder"] {
  padding-right: 2px;
}

.site-banner {
  li[class^='awsui_flash-list-item_']>div {
    border-start-start-radius: 0 !important;
    border-start-end-radius: 0 !important;
    border-end-start-radius: 0 !important;
    border-end-end-radius: 0 !important;
  }
}


.note {
  .controls {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 2px;
  }

  &:hover {
    .controls {
      display: block;
    }
  }
}

h1 {
  font-size: 3rem
}

h2 {
  color: #027e36;
  padding-top: 0px;
  margin-top: 0px;
  line-height: 3.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

h4 {
  color: #027e36;
  text-transform: uppercase;
}
