/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_4yi2u_nvz0x_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
}

.awsui_outline_4yi2u_nvz0x_178:not(#\9) {
  position: relative;
}
.awsui_outline_4yi2u_nvz0x_178:not(#\9) {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
.awsui_outline_4yi2u_nvz0x_178:not(#\9)::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 2px);
  inset-block-start: calc(-1 * 2px);
  inline-size: calc(100% + 2px + 2px);
  block-size: calc(100% + 2px + 2px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_toggle-control_4yi2u_nvz0x_200:not(#\9) {
  forced-color-adjust: none;
  margin-block-start: calc((var(--line-height-body-m-30ar75, 20px) - 16px) / 2);
  min-block-size: 16px;
  min-inline-size: 24px;
  block-size: 16px;
  inline-size: 24px;
  background: var(--color-background-toggle-default-ywflhx, #424650);
  border-start-start-radius: 8px;
  border-start-end-radius: 8px;
  border-end-start-radius: 8px;
  border-end-end-radius: 8px;
}
.awsui_toggle-control-checked_4yi2u_nvz0x_213:not(#\9) {
  background: var(--color-background-control-checked-hz4k40, #006ce0);
}
.awsui_toggle-control-disabled_4yi2u_nvz0x_216:not(#\9) {
  background: var(--color-background-control-disabled-5cbvij, #dedee3);
}
.awsui_toggle-control-disabled_4yi2u_nvz0x_216.awsui_toggle-control-checked_4yi2u_nvz0x_213:not(#\9) {
  background: var(--color-background-toggle-checked-disabled-wk1i1j, #b8e7ff);
}
.awsui_toggle-control-readonly_4yi2u_nvz0x_222:not(#\9) {
  background: var(--color-background-control-disabled-5cbvij, #dedee3);
}

.awsui_toggle-handle_4yi2u_nvz0x_226:not(#\9) {
  display: block;
  position: absolute;
  border-start-start-radius: 6px;
  border-start-end-radius: 6px;
  border-end-start-radius: 6px;
  border-end-end-radius: 6px;
  background: var(--color-foreground-control-default-p84gz5, #ffffff);
  box-shadow: 1px 1px rgba(0, 0, 0, 0.25);
  inline-size: 12px;
  block-size: 12px;
  inset-block-start: 2px;
  inset-inline-start: 2px;
  transition: transform var(--motion-duration-fast-sbm3r7, 90ms) var(--motion-easing-ease-out-quart-rrej5m, cubic-bezier(0.165, 0.84, 0.44, 1)), background-color var(--motion-duration-fast-sbm3r7, 90ms) var(--motion-easing-ease-out-quart-rrej5m, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_toggle-handle_4yi2u_nvz0x_226:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_toggle-handle_4yi2u_nvz0x_226:not(#\9), .awsui-mode-entering .awsui_toggle-handle_4yi2u_nvz0x_226:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_toggle-handle-checked_4yi2u_nvz0x_251:not(#\9) {
  transform: translateX(8px);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_toggle-handle-checked_4yi2u_nvz0x_251:not(#\9):dir(rtl) {
  transform: translateX(-8px);
}
.awsui_toggle-handle-disabled_4yi2u_nvz0x_258:not(#\9) {
  background: var(--color-foreground-control-disabled-c64ihu, #ffffff);
  box-shadow: none;
}
.awsui_toggle-handle-readonly_4yi2u_nvz0x_262:not(#\9):not(.awsui_toggle-handle-disabled_4yi2u_nvz0x_258) {
  box-shadow: none;
}
.awsui_toggle-handle-readonly_4yi2u_nvz0x_262:not(#\9):not(.awsui_toggle-handle-disabled_4yi2u_nvz0x_258).awsui_toggle-handle-checked_4yi2u_nvz0x_251 {
  background: var(--color-foreground-control-read-only-e43ooq, #656871);
}