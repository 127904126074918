.signature-container {
  background-color: yellow;
  width: 300px;
  padding: 12px 20px 0 20px;
  overflow: auto;

  & .signature {
    width: 100%;
    outline: none;
    background-color: transparent;
    margin-bottom: 12px;
    font-family: "Great Vibes", cursive;
    font-size: 32px;
    border: none;
    border-bottom: dashed 1px #000;
  }

  & .date {
    padding-bottom: 12px;
  }
}