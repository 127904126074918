/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable selector-max-type */
.awsui_tabs-header_14rmt_11l2w_290:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  display: flex;
  flex-wrap: wrap;
}

.awsui_tab-header-scroll-container_14rmt_11l2w_299:not(#\9) {
  display: flex;
  flex-grow: 1;
  max-inline-size: 100%;
}

.awsui_tabs-header-list_14rmt_11l2w_305:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  inline-size: 100%;
  scroll-snap-type: inline proximity;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.awsui_tabs-header-list_14rmt_11l2w_305:not(#\9)::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.awsui_pagination-button_14rmt_11l2w_323:not(#\9) {
  margin-block: var(--space-scaled-s-aqzyko, 12px);
  margin-inline: 0;
  padding-block: 0;
  padding-inline: var(--space-xxs-p8yyaw, 4px);
  display: flex;
}
.awsui_pagination-button-left_14rmt_11l2w_330:not(#\9) {
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-control-disabled-qc5csz, #dedee3);
}
.awsui_pagination-button-left-scrollable_14rmt_11l2w_333:not(#\9) {
  z-index: 1;
  box-shadow: 5px 0px 4px -3px var(--color-border-tabs-shadow-enjrr2, rgba(15, 20, 26, 0.12)), 1px 0px 0px 0px var(--color-border-tabs-shadow-enjrr2, rgba(15, 20, 26, 0.12));
}
.awsui_pagination-button-right_14rmt_11l2w_337:not(#\9) {
  border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-control-disabled-qc5csz, #dedee3);
}
.awsui_pagination-button-right-scrollable_14rmt_11l2w_340:not(#\9) {
  z-index: 1;
  box-shadow: -5px 0px 4px -3px var(--color-border-tabs-shadow-enjrr2, rgba(15, 20, 26, 0.12)), -1px 0px 0px 0 var(--color-border-tabs-shadow-enjrr2, rgba(15, 20, 26, 0.12));
}

.awsui_actions-container_14rmt_11l2w_345:not(#\9) {
  flex-shrink: 0;
  align-self: center;
  padding-block: var(--space-xs-zb16t3, 8px);
  padding-inline: var(--space-xs-zb16t3, 8px);
  margin-inline-start: auto;
}

.awsui_tabs-tab_14rmt_11l2w_353:not(#\9) {
  list-style: none;
  padding-block: 0;
  padding-inline: 0;
  flex-shrink: 0;
  display: flex;
  max-inline-size: calc(90% - var(--space-l-t419sm, 20px));
  scroll-snap-align: start;
}

.awsui_tabs-tab-label_14rmt_11l2w_363:not(#\9) {
  display: flex;
  align-items: center;
  padding-inline: var(--space-xs-zb16t3, 8px);
  padding-block: var(--space-scaled-2x-xxs-7v8ivz, 4px);
  text-align: start;
  position: relative;
  min-inline-size: 0;
  word-break: break-word;
}

.awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9) {
  position: relative;
  border-block: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  border-inline: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  padding-inline: var(--space-xs-zb16t3, 8px);
  display: flex;
  align-items: stretch;
}
.awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9), .awsui_tabs-tab-header-container_14rmt_11l2w_374 > button:not(#\9) {
  background-color: transparent;
}
.awsui_tabs-tab-header-container_14rmt_11l2w_374 > .awsui_tabs-tab-dismiss_14rmt_11l2w_385:not(#\9), .awsui_tabs-tab-header-container_14rmt_11l2w_374 > .awsui_tabs-tab-action_14rmt_11l2w_385:not(#\9) {
  position: relative;
  display: flex;
  align-items: center;
}
.awsui_tabs-tab-header-container_14rmt_11l2w_374.awsui_refresh_14rmt_11l2w_390 > span:not(#\9):first-of-type {
  margin-inline-start: calc(-1 * var(--space-scaled-xs-26e2du, 8px));
}

.awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394):after {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  inline-size: calc(100% - 1px);
  inset-block-end: calc(-1 * var(--border-divider-section-width-1061zr, 1px));
  block-size: var(--border-active-width-9dmqf4, 4px);
  border-start-start-radius: var(--border-radius-tabs-focus-ring-1q0rjo, 20px);
  border-start-end-radius: var(--border-radius-tabs-focus-ring-1q0rjo, 20px);
  border-end-start-radius: var(--border-radius-tabs-focus-ring-1q0rjo, 20px);
  border-end-end-radius: var(--border-radius-tabs-focus-ring-1q0rjo, 20px);
  background: var(--color-border-tabs-underline-a5fmhd, #006ce0);
  opacity: 0;
}
.awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394).awsui_refresh_14rmt_11l2w_390:after {
  transition: opacity var(--motion-duration-refresh-only-medium-nf6485, 165ms) var(--motion-easing-refresh-only-c-vg1m9h, cubic-bezier(0.84, 0, 0.16, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394).awsui_refresh_14rmt_11l2w_390:after {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394).awsui_refresh_14rmt_11l2w_390:after, .awsui-mode-entering .awsui_tabs-tab-header-container_14rmt_11l2w_374:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394).awsui_refresh_14rmt_11l2w_390:after {
  animation: none;
  transition: none;
}

.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):not(:last-child) > .awsui_tabs-tab-header-container_14rmt_11l2w_374 {
  margin-inline-end: calc(-1 * var(--border-divider-section-width-1061zr, 1px));
}
.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):not(:last-child) > .awsui_tabs-tab-header-container_14rmt_11l2w_374:before {
  content: "";
  position: absolute;
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-tabs-divider-djfdfj, #c6c6cd);
  inset: var(--space-scaled-s-aqzyko, 12px) 0;
  opacity: 1;
}
.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):not(:last-child) > .awsui_tabs-tab-header-container_14rmt_11l2w_374.awsui_refresh_14rmt_11l2w_390:before {
  inset: calc(var(--space-static-s-n2eb28, 12px) - var(--border-active-width-9dmqf4, 4px)) 0;
}

.awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9) {
  position: relative;
  display: flex;
  align-items: stretch;
  text-decoration: none;
  cursor: pointer;
  padding-block-start: calc(var(--space-scaled-s-aqzyko, 12px) - 2px);
  padding-block-end: calc(var(--space-scaled-s-aqzyko, 12px) - 1px);
  padding-inline: 0;
  margin-block-start: 1px;
  border-block: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  border-inline: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  font-size: var(--font-tabs-size-8zzqp2, 16px);
  line-height: var(--font-tabs-line-height-dzo7k3, 20px);
  font-weight: var(--font-wayfinding-link-active-weight-rbmzei, 700);
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  padding-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1px);
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_tabs-tab-link_14rmt_11l2w_436.awsui_refresh_14rmt_11l2w_390:not(#\9) {
  padding-block-start: calc(var(--space-static-xs-7sfb63, 8px) - 1px);
  padding-block-end: calc(var(--space-static-xs-7sfb63, 8px) - 1px);
  margin-block-start: 0;
}
.awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9):hover {
  color: var(--color-text-accent-fptgkc, #006ce0);
}
.awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9):focus {
  outline: none;
}
body[data-awsui-focus-visible=true] .awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9):focus {
  z-index: 1;
  border-inline-end-color: transparent;
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-tabs-focus-outline-gutter-xm37ly, -8px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_tabs-tab-link_14rmt_11l2w_436:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-tabs-focus-outline-gutter-xm37ly, -8px));
  inset-block-start: calc(-1 * var(--space-tabs-focus-outline-gutter-xm37ly, -8px));
  inline-size: calc(100% + var(--space-tabs-focus-outline-gutter-xm37ly, -8px) + var(--space-tabs-focus-outline-gutter-xm37ly, -8px));
  block-size: calc(100% + var(--space-tabs-focus-outline-gutter-xm37ly, -8px) + var(--space-tabs-focus-outline-gutter-xm37ly, -8px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):first-child {
  margin-inline-start: 1px;
  scroll-margin-inline-start: 1px;
}
.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):first-child > .awsui_tabs-tab-header-container_14rmt_11l2w_374 {
  padding-inline-start: calc(var(--space-xs-zb16t3, 8px) - 1px);
}

.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):last-child {
  margin-inline-end: 1px;
  scroll-margin-inline-end: 1px;
}
.awsui_tabs-tab_14rmt_11l2w_353:not(#\9):last-child > .awsui_tabs-tab-header-container_14rmt_11l2w_374 {
  padding-inline-end: calc(var(--space-xs-zb16t3, 8px) - 1px);
}

.awsui_tabs-tab-disabled_14rmt_11l2w_394:not(#\9), .awsui_tabs-tab-disabled_14rmt_11l2w_394:not(#\9):hover {
  cursor: default;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
  font-weight: var(--font-tabs-disabled-weight-1xcs5l, 700);
}

.awsui_tabs-tab-active_14rmt_11l2w_514:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394) {
  color: var(--color-text-accent-fptgkc, #006ce0);
}
.awsui_tabs-tab-active_14rmt_11l2w_514:not(#\9):not(.awsui_tabs-tab-disabled_14rmt_11l2w_394):after {
  opacity: 1;
}

.awsui_tabs-header-with-divider_14rmt_11l2w_521:not(#\9) {
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-tabs-divider-djfdfj, #c6c6cd);
}

.awsui_tabs-tab-focusable_14rmt_11l2w_525:not(#\9) {
  /* used to manage focusable logic */
}

.awsui_root_14rmt_11l2w_529:not(#\9) {
  /* used in test-utils or tests */
}

.awsui_tabs_14rmt_11l2w_290:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
  inline-size: 100%;
}

.awsui_tabs-content_14rmt_11l2w_567:not(#\9) {
  display: none;
}

.awsui_fit-height_14rmt_11l2w_571:not(#\9) {
  display: flex;
  flex-direction: column;
  block-size: 100%;
}

.awsui_tabs-content-active_14rmt_11l2w_577:not(#\9) {
  display: block;
  flex: 1;
}
body[data-awsui-focus-visible=true] .awsui_tabs-content-active_14rmt_11l2w_577:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: 2px;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_tabs-content-wrapper_14rmt_11l2w_591.awsui_with-paddings_14rmt_11l2w_591 > .awsui_tabs-content_14rmt_11l2w_567:not(#\9) {
  padding-block: var(--space-scaled-m-mo5yse, 16px);
  padding-inline: 0;
}
.awsui_fit-height_14rmt_11l2w_571 > .awsui_tabs-content-wrapper_14rmt_11l2w_591:not(#\9) {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.awsui_fit-height_14rmt_11l2w_571 > .awsui_tabs-content-wrapper_14rmt_11l2w_591 > .awsui_tabs-container-content-wrapper_14rmt_11l2w_602:not(#\9) {
  block-size: 100%;
  display: flex;
  flex-direction: column;
}
.awsui_tabs-container-content-wrapper_14rmt_11l2w_602.awsui_with-paddings_14rmt_11l2w_591 > .awsui_tabs-content_14rmt_11l2w_567:not(#\9) {
  padding-block-start: var(--space-tabs-content-top-dedu0k, 12px);
  padding-block-end: var(--space-scaled-l-0hpmd7, 20px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
}

.awsui_disabled-reason-tooltip_14rmt_11l2w_613:not(#\9) {
  /* used in test-utils or tests */
}

.awsui_tabs-tab-focused_14rmt_11l2w_617:not(#\9) {
  /* used to manage focusable state for disabled with reason tabs */
}