/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_segment__path_1edmh_1b6e4_161:not(#\9) {
  transition: opacity var(--motion-duration-transition-quick-6npj01, 90ms) var(--motion-easing-transition-quick-l9jfsx, linear);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_segment__path_1edmh_1b6e4_161:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_segment__path_1edmh_1b6e4_161:not(#\9), .awsui-mode-entering .awsui_segment__path_1edmh_1b6e4_161:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_segment_1edmh_1b6e4_161:not(#\9) {
  transition: opacity var(--motion-duration-transition-quick-6npj01, 90ms) var(--motion-easing-transition-quick-l9jfsx, linear);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_segment_1edmh_1b6e4_161:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_segment_1edmh_1b6e4_161:not(#\9), .awsui-mode-entering .awsui_segment_1edmh_1b6e4_161:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_segment_1edmh_1b6e4_161 > .awsui_segment__hover_1edmh_1b6e4_188:not(#\9) {
  transition: opacity var(--motion-duration-transition-quick-6npj01, 90ms) var(--motion-easing-transition-quick-l9jfsx, linear);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_segment_1edmh_1b6e4_161 > .awsui_segment__hover_1edmh_1b6e4_188:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_segment_1edmh_1b6e4_161 > .awsui_segment__hover_1edmh_1b6e4_188:not(#\9), .awsui-mode-entering .awsui_segment_1edmh_1b6e4_161 > .awsui_segment__hover_1edmh_1b6e4_188:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_label_1edmh_1b6e4_202:not(#\9) {
  transition: opacity var(--motion-duration-transition-quick-6npj01, 90ms) var(--motion-easing-transition-quick-l9jfsx, linear);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_label_1edmh_1b6e4_202:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_label_1edmh_1b6e4_202:not(#\9), .awsui-mode-entering .awsui_label_1edmh_1b6e4_202:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1edmh_1b6e4_216:not(#\9) {
  /* used in test-utils */
}

.awsui_content_1edmh_1b6e4_220.awsui_content--small_1edmh_1b6e4_220:not(#\9) {
  min-block-size: calc(2 * (50px + var(--space-xs-zb16t3, 8px) + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--small_1edmh_1b6e4_220.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (30px + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--small_1edmh_1b6e4_220.awsui_content--without-labels_1edmh_1b6e4_226:not(#\9) {
  min-block-size: calc(2 * (50px + var(--space-xs-zb16t3, 8px)));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--small_1edmh_1b6e4_220.awsui_content--without-labels_1edmh_1b6e4_226.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (30px));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--medium_1edmh_1b6e4_232:not(#\9) {
  min-block-size: calc(2 * (100px + var(--space-s-34lx8l, 12px) + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--medium_1edmh_1b6e4_232.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (100px + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--medium_1edmh_1b6e4_232.awsui_content--without-labels_1edmh_1b6e4_226:not(#\9) {
  min-block-size: calc(2 * (100px + var(--space-s-34lx8l, 12px)));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--medium_1edmh_1b6e4_232.awsui_content--without-labels_1edmh_1b6e4_226.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (100px));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--large_1edmh_1b6e4_244:not(#\9) {
  min-block-size: calc(2 * (140px + var(--space-s-34lx8l, 12px) + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--large_1edmh_1b6e4_244.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (140px + calc(2 * var(--line-height-body-m-30ar75, 20px))));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--large_1edmh_1b6e4_244.awsui_content--without-labels_1edmh_1b6e4_226:not(#\9) {
  min-block-size: calc(2 * (140px + var(--space-s-34lx8l, 12px)));
}
.awsui_content_1edmh_1b6e4_220.awsui_content--large_1edmh_1b6e4_244.awsui_content--without-labels_1edmh_1b6e4_226.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  min-block-size: calc(2 * (140px));
}

.awsui_content--fit-height_1edmh_1b6e4_223:not(#\9) {
  flex: 1;
}

.awsui_status-container_1edmh_1b6e4_261:not(#\9) {
  /* used in test utils */
}

.awsui_chart-container_1edmh_1b6e4_265:not(#\9) {
  display: flex;
  flex: 1;
}
.awsui_chart-container--fit-height_1edmh_1b6e4_269:not(#\9) {
  block-size: 100%;
  min-block-size: inherit;
}

.awsui_chart-container-chart-plot_1edmh_1b6e4_274:not(#\9) {
  display: contents;
}
.awsui_chart-container-chart-plot--fit-height_1edmh_1b6e4_277:not(#\9) {
  display: block;
  position: absolute;
  inset: 0;
}

.awsui_inner-content_1edmh_1b6e4_283:not(#\9) {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  inset: 0;
  pointer-events: none;
}

.awsui_segment__highlight_1edmh_1b6e4_294:not(#\9) {
  stroke: none;
  opacity: 0;
}

.awsui_segment_1edmh_1b6e4_161:not(#\9) {
  stroke: var(--color-background-container-content-z79u4u, #ffffff);
  stroke-width: 2px;
  stroke-linejoin: round;
  opacity: 1;
  cursor: pointer;
}
.awsui_segment_1edmh_1b6e4_161:not(#\9):focus {
  outline: none;
}
.awsui_segment_1edmh_1b6e4_161.awsui_segment--dimmed_1edmh_1b6e4_309:not(#\9) {
  opacity: 0.35;
}
.awsui_segment_1edmh_1b6e4_161.awsui_segment--highlighted_1edmh_1b6e4_312 > .awsui_segment__highlight_1edmh_1b6e4_294:not(#\9), .awsui_segment_1edmh_1b6e4_161:not(#\9):hover:not(.awsui_segment--dimmed_1edmh_1b6e4_309) > .awsui_segment__highlight_1edmh_1b6e4_294 {
  opacity: 1;
}

.awsui_label_1edmh_1b6e4_202:not(#\9) {
  color: var(--color-text-heading-default-jen9ei, #0f141a);
  opacity: 1;
  /* stylelint-disable-next-line selector-max-type */
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_label_1edmh_1b6e4_202 > line:not(#\9) {
  stroke: var(--color-stroke-chart-line-i586tw, #8c8c94);
  stroke-width: var(--border-divider-section-width-1061zr, 1px);
}
.awsui_label_1edmh_1b6e4_202 > div:not(#\9) {
  margin-block-start: -0.75em;
}
.awsui_label_1edmh_1b6e4_202.awsui_label--dimmed_1edmh_1b6e4_329:not(#\9) {
  opacity: 0.35;
}
.awsui_label_1edmh_1b6e4_202.awsui_label--align-right_1edmh_1b6e4_332:not(#\9) {
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_label_1edmh_1b6e4_202.awsui_label--align-right_1edmh_1b6e4_332 > div:not(#\9) {
  text-align: end;
}

/* stylelint-disable-next-line selector-max-type */
.awsui_label-text_1edmh_1b6e4_340 > text:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  font-weight: var(--font-display-label-weight-815ja9, 700);
  fill: var(--color-text-heading-default-jen9ei, #0f141a);
  dominant-baseline: middle;
}

text.awsui_label__description_1edmh_1b6e4_348:not(#\9) {
  font-size: var(--font-chart-detail-size-1n389u, 12px);
  font-weight: 400;
  color: var(--color-text-group-label-8pk3es, #424650);
  fill: var(--color-text-group-label-8pk3es, #424650);
}

.awsui_label-line_1edmh_1b6e4_355:not(#\9) {
  /* used in component code */
}

.awsui_label--highlighted_1edmh_1b6e4_359:not(#\9) {
  /* used in test-utils */
}

.awsui_popover-header_1edmh_1b6e4_363:not(#\9) {
  display: inline-flex;
  align-items: flex-start;
}