/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_11n0s_1hwhs_9:not(#\9) {
  /* used in test-utils */
}

.awsui_empty_11n0s_1hwhs_13:not(#\9) {
  color: var(--color-text-empty-o6660v, #656871);
}