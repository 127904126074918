.anim-input {
  overflow: hidden;
}

.input-container {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 1em;
  max-width: 100%;
  width: calc(100% - 2em);
  vertical-align: top;
}

.input_field {
  position: relative;
  display: block;
  float: right;
  padding: 0.8em;
  width: 60%;
  border: none;
  border-radius: 0;
  background: #f0f0f0;
  color: #aaa;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
}

.input-field-anim {
  margin-top: 1em;
  padding: 0.85em 0.15em;
  width: 100%;
  background: transparent;
  color: #595F6E;
}

.input_field:focus {
  outline: none;
}

.input-field-anim {
  margin-top: 1em;
  padding: 0.85em 0.15em;
  width: 100%;
  background: transparent;
  color: #595F6E;
}

.input-label-anim {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
}

.input_label {
  display: inline-block;
  float: right;
  padding: 0 1em;
  width: 40%;
  color: #6a7989;
  font-weight: bold;
  font-size: 70.25%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hoshi */

.input-field-anim {
  margin-top: 1em;
  padding: 0.35em 0.15em 1.5em 0.15em;
  width: 100%;
  background: transparent;
  color: #595F6E;
  font-size: 16px;
  font-weight: 200;
}

.input-label-anim {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 0.25em;
  width: 100%;
  height: calc(100% - 1em);
  text-align: left;
  pointer-events: none;
}

.input-label-content-anim {
  position: absolute;
  font-size: 16px;
  font-weight: 200;
  color: #000;
  font-weight: 400;
}

.input-label-anim::before,
.input-label-anim::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 10px);
  border-bottom: 1px solid #000;
}

.input-label-anim::after {
  margin-top: 1px;
  border-bottom: 2px solid red;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.input-label-anim-color-1::after {
  border-color: hsl(200, 100%, 50%);
}

.input-label-anim-color-2::after {
  border-color: hsl(160, 100%, 50%);
}

.input-label-anim-color-3::after {
  border-color: hsl(20, 100%, 50%);
}

.input-field-anim:focus+.input-label-anim::after,
.input--filled .input-label-anim::after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.input-field-anim:focus+.input-label-anim .input-label-content-anim,
.input--filled .input-label-content-anim {
  -webkit-animation: anim-1 0.3s forwards;
  animation: anim-1 0.3s forwards;
  font-size: 16px;
  top: 3px;
}

@-webkit-keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }

  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

@keyframes anim-1 {
  50% {
    opacity: 0;
    -webkit-transform: translate3d(1em, 0, 0);
    transform: translate3d(1em, 0, 0);
  }

  51% {
    opacity: 0;
    -webkit-transform: translate3d(-1em, -40%, 0);
    transform: translate3d(-1em, -40%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, -40%, 0);
    transform: translate3d(0, -40%, 0);
  }
}

.input-label-color-1::after {
  border-color: hsl(200, 100%, 50%);
}

.input__label--hoshi-color-2::after {
  border-color: hsl(160, 100%, 50%);
}

.input__label--hoshi-color-3::after {
  border-color: hsl(20, 100%, 50%);
}

.property-form .input-label-content-anim {
  color: #002a5c;
}

.property-form .input-container {
  margin: 1em 0 !important;
  width: calc(100%);
  color: #ffffff !important;
}

.property-form .input-field-anim {
  color: #ffffff;
}

.property-form .input-label-anim::before,
.input-label-anim::after {
  border-bottom: 1px solid #002a5c;
}
