div.passive-enrollment-parent-edit {
  span[class^="awsui_title"] {
    &:after {
      content: "" !important;
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
  }

  div[class*="awsui_key-label"] {
    font-size: 14px !important;
  }
}