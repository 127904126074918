/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_handle_sdha6_1q7hk_145:not(#\9) {
  appearance: none;
  color: var(--color-text-interactive-default-tkx8fe, #424650);
  background: transparent;
  inline-size: fit-content;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  touch-action: none;
}
.awsui_handle-size-normal_sdha6_1q7hk_153:not(#\9) {
  block-size: var(--line-height-body-m-30ar75, 20px);
  padding-inline: var(--space-scaled-xxxs-27y4hv, 2px);
}
.awsui_handle-size-small_sdha6_1q7hk_157:not(#\9) {
  block-size: var(--line-height-body-s-7zv1j5, 16px);
}
.awsui_handle-drag-indicator_sdha6_1q7hk_160:not(#\9) {
  cursor: grab;
}
.awsui_handle-drag-indicator_sdha6_1q7hk_160:not(#\9):active {
  cursor: grabbing;
}
.awsui_handle-resize-area_sdha6_1q7hk_166:not(#\9) {
  cursor: nwse-resize;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_handle-resize-area_sdha6_1q7hk_166:not(#\9):dir(rtl) {
  cursor: nesw-resize;
}
.awsui_handle-resize-horizontal_sdha6_1q7hk_173:not(#\9) {
  cursor: ew-resize;
}
.awsui_handle-resize-vertical_sdha6_1q7hk_176:not(#\9) {
  cursor: ns-resize;
}
.awsui_handle-disabled_sdha6_1q7hk_179:not(#\9) {
  cursor: default;
}
.awsui_handle_sdha6_1q7hk_145:not(#\9):hover {
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_handle_sdha6_1q7hk_145:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
body[data-awsui-focus-visible=true] .awsui_handle_sdha6_1q7hk_145:not(#\9):focus:not(.awsui_hide-focus_sdha6_1q7hk_189) {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_handle_sdha6_1q7hk_145:not(#\9):focus:not(.awsui_hide-focus_sdha6_1q7hk_189) {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_handle_sdha6_1q7hk_145:not(#\9):focus:not(.awsui_hide-focus_sdha6_1q7hk_189)::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 0px);
  inset-block-start: calc(-1 * 0px);
  inline-size: calc(100% + 0px + 0px);
  block-size: calc(100% + 0px + 0px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_resize-icon_sdha6_1q7hk_211:not(#\9) {
  stroke: var(--color-text-interactive-default-tkx8fe, #424650);
}
.awsui_resize-icon_sdha6_1q7hk_211:not(#\9):hover {
  stroke: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_resize-icon-vertical_sdha6_1q7hk_217:not(#\9) {
  margin-block: auto;
  margin-inline: auto;
}
.awsui_resize-icon-horizontal_sdha6_1q7hk_221:not(#\9) {
  transform: rotate(90deg);
}