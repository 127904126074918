/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1t8ew_1brmj_9:not(#\9) {
  margin-block-start: var(--space-xs-zb16t3, 8px);
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_root_1t8ew_1brmj_9 > hr:not(#\9) {
  border-block: none;
  border-inline: none;
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  margin-block: var(--space-xs-zb16t3, 8px);
  margin-inline: 0;
}